import React from "react";
import "../styles/privacy-policy.css";

const PrivacyPolicy = props => {
    
    return (
        <div className='policy'>
            <h4>Privacy Policy</h4>
            <p>Welcome to Brawl IQ, the MMA analytics platform! At Brawl IQ, we are committed to protecting your privacy and ensuring the security
                of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you
                use our website and services.</p>
            <h6>1. Information We Collect</h6>
            <p>We collect various types of information when you use Brawl IQ:</p>
            <ul>
                <li><b>Usage Information:</b> We collect information about how you interact with our platform and the features you use.</li>
                <li><b>Device Information:</b> We may collect information about the device you use to access Brawliq, including device type, operating system, and IP address.</li>
                <li><b>Cookies and Similar Technologies:</b> We use cookies and similar technologies to enhance your experience and collect information about your browsing behavior.</li>
            </ul>
            <h6>2. How We Use Your Information</h6>
            <p>We use the information we collect for the following purposes:</p>
            <ul>
                <li>To provide and improve our services.</li>
                <li>To personalize your experience and tailor content to your interests.</li>
                <li>To analyze trends and user behavior to improve our platform and services.</li>
                <li>To ensure the security and integrity of our platform.</li>
            </ul>
            <h6>3. Data Sharing and Disclosure</h6>
            <p>We do not share your information with third parties except as described below:</p>
            <ul>
                <li><b>Service Providers:</b> We may engage third-party service providers to assist us in operating our platform and providing our services.</li>
                <li><b>Legal Compliance:</b> We may disclose your information to comply with legal obligations, enforce our policies, or respond to legal requests.</li>
            </ul>
            <h6>4. Data Security</h6>
            <p>We take the security of your information seriously and implement appropriate measures to protect it from unauthorized access, alteration, disclosure, or destruction.</p>
            <h6>5. Your Choices</h6>
            <p>You can choose to disable cookies in your browser settings, but please note that doing so may affect your experience with our platform.</p>
            <h6>6. Children's Privacy</h6>
            <p>Brawl IQ is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13 years of age.</p>
            <h6>7. Changes to This Policy</h6>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website.</p>
            <h6>8. Contact Us</h6>
            <p>If you have any questions or concerns about our Privacy Policy or practices, please contact us at <a href="mailto:brawliqanalytics@gmail.com" style={{ color: 'blue', textDecoration: 'underline' }}>brawliqanalytics@gmail.com</a>.</p>
            <p>By using Brawl IQ, you consent to the collection and use of your information as described in this Privacy Policy.</p>
        </div>
    );
};

export default PrivacyPolicy;