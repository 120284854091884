// src/App.js
import React, { useEffect, useState } from "react";
import axios from "axios";

import OPENAI_API_KEY from "./config/openai";

const ChatbotApp = props => {
  const [response, setResponse] = useState("");

  useEffect(() => {
    console.log('render')
    console.log(props.prompt)
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            //model: "gpt-3.5-turbo-16k",
            model: "gpt-4o",
            messages: [{"role": "system", "content": "You are data analyst and are given statistics for 2 UFC fighters in a JSON format. The SApM_rank, SLpM_rank, TD_Avg_rank, Sub_Avg_rank, Str_Acc_rank, Str_Def_rank, TD_Acc_rank, TD_Def_rank columns denote the percentile that the fighter is in compared to all fighters in the same weight class. Generally, the higher the fight metric is the better a fighter is. Fighters can also have advantage through height, weight, and reach. Some fight stances work well against others. Older fighters might be at a disadvantage. Consider those factors and all fight metrics when making a comparison. Who would be favoured to win in a MMA match based only on the provided statistics and why? Only give conclusion in a few short sentences.\
                \
                below are column definitions:\
                fighter_name: name of the fighter\
                Height: height of the fighter\
                Weight: weight of the fighter\
                Reach: wingspan of the fighter\
                Stance: fighting stance\
                DOB: date of birth\
                SLpM: significant strikes landed per minute\
                Str_Acc: significant striking accuracy\
                SApM: significant strikes absorbed per minute\
                Str_Def: significant strikes defence (the % of opponents strikes that did not land)\
                TD_Avg: average takedowns landed per 15 minutes\
                TD_Acc: takedown accuracy\
                TD_Def: takedown defence (the % of opponents TD attempts that did not land)\
                Sub_Avg: average submissions attempted per 15 minutes\
                Weight_class: weight class of the fighter\
                SApM_rank: SApM percentile\
                SLpM_rank: SLpM percentile\
                TD_Avg_rank: TD_Avg percentile\
                Sub_Avg_rank: Sub_Avg percentile\
                Str_Acc_rank: Str_Acc percentile\
                Str_Def_rank: Str_Def percentile\
                TD_Acc_rank: TD_Acc percentile\
                TD_Def_rank: TD_Def percentile\
                \
                Answer:\n"},
            {"role": "user", "content": props.prompt}],
            temperature: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${OPENAI_API_KEY}
`,
            },
          },
        );
        //setResponse(response.data.choices[0].message.content.replaceAll("Observation", "</p><p>Observation").replaceAll("</p><p>Observation 1", "<p>Observation 1")+"</p>");
        setResponse(response.data.choices[0].message.content);
        //console.log(response.data.choices[0].message.content.replaceAll("Observation", "</p><p>Observation").replaceAll("</p><p>Observation 1", "<p>Observation 1")+"</p>");
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [props.prompt]);
  return (
    <div>
      <p style={{"color":"#282c34"}}>Who is Favored to Win?</p>
      <div dangerouslySetInnerHTML={{__html:response}} />
    </div>
  );
}

export default ChatbotApp;