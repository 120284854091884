import React, { useState, useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";
import RestaurantDataService from "../services/restaurant";
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import '../styles/home.css';

import AddReview from "../components/add-review";
import Restaurant from "../components/restaurants";
import RestaurantsList from "../components/restaurants-list";
import Login from "../components/login";
import Chart from "../components/chart";
import Sidebar from "../components/sidebar";
import SlideShow from "../components/slideshow";
import TwitterContainer from "../components/twittercontainer";
import Posts from "../components/posts";
import SinglePost from "../components/singlepost";
import ContactUs from "../components/contact-us";

function Home() {
  const [postsData, setPostsData] = useState([])

  const retrievePosts = () => {
    RestaurantDataService.getPosts()
      .then(response => {
        console.log(response.data);
        setPostsData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrievePosts();
  }, []);

  return (
      <div className="content">
        <div style={{position: 'relative', height: '400px', width: '100%', border: '4px solid #b78c33'}}>
          <a href="#">Learn More</a>
        </div>
        <div style={{position: 'relative', width: '100%', marginTop: '1%', border: '4px solid #b78c33'}}><SlideShow /></div>
        <div className='grid' style={{position: 'relative', width: '100%', marginTop: '1%'}}>
          <div className="blog" style={{border: '4px solid #b78c33'}}>
            <h3 className="title">Latest Blog Posts</h3>
            <Posts postsData={postsData}/>
            <button type="submit" className="btn btn-primary">View More</button>
            {/* <a href="#">View More</a> */}
          </div>
          <div className="tweets" style={{border: '4px solid #b78c33'}}>
            <TwitterContainer />
          </div>
        </div>
        <div style={{position: 'relative', height: '450px', width: '100%', borderBottom: '4px solid #b78c33', background: '#EAEAEA'}}>
          <ContactUs />
        </div>
      </div>
  );
}

export default Home;
