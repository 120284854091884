import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import {Link} from "react-router-dom";
import "../styles/sidebar.css";

export default props => {
  return (
    <Menu>
      <a className="menu-item" href="/">
        UFC Head to Head
      </a>
      {/*<a className="menu-item">
        <i class="fa fa-th-large" aria-hidden="true"></i>
        <Link to={"/chart"}>
          Fight Dashboard
        </Link>
      </a>
      <a className="menu-item" href="/dashboard">
        Scout IQ Database
      </a>
      <a className="menu-item" href="/dashboard">
        <i class="fa fa-users" aria-hidden="true"></i> About Us
      </a>
      <a className="menu-item" href="/chart">
        <i class="fa fa-coffee" aria-hidden="true"></i> Contact Us
      </a>*/}
    </Menu>
  );
};