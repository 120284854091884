import {useState, useEffect} from 'react'
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react'
import "../styles/chatbot.css";

const API_KEY = "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";
// "Explain things like you would to a 10 year old learning how to code."
const systemMessage = { //  Explain things like you're talking to a software professional with 5 years of experience.
  "role": "system", "content": "Explain things like you're talking to a software professional with 2 years of experience."
}

const Chatbot = ({isOpen})  => {
    const [typing, setIsTyping] = useState(false);
    const [messages, setMessages] = useState([
        {
            message: "Hello! I am Brawly, your AI Assistant. How may I assist you?",
            sender: "ChatGPT",
            direction: "incoming",
            type: "text",
            payload: "Hello! I am Brawly, your AI Assistant. How may I assist you?"
        }
    ])
    const [browserHeight, setBrowserHeight] = useState(window.innerHeight);

    useEffect(() => {
      function handleResize() {
        setBrowserHeight(window.innerHeight);
        document.documentElement.style.setProperty('--browser-height', `${window.innerHeight}px`);
      }
  
      // Initialize the custom property value before the component mounts
      document.documentElement.style.setProperty('--browser-height', `${window.innerHeight}px`);
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const handleSend = async (message) => {
        const newMessage = {
            message: message,
            sender: "user",
            direction: "outgoing",
            type: "text",
            payload: message
        }

        const newMessages = [...messages, newMessage]; // all the old messages + the new message

        // update our messages state
        setMessages(newMessages);

        // set a typing indicator (chatgpt is typing)
        setIsTyping(true);

        // process message to chatgpt
        await processMessageToChatGPT(newMessages);
    }

    async function processMessageToChatGPT(chatMessages) { // messages is an array of messages
        // Format messages for chatGPT API
        // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
        // So we need to reformat
    
        let apiMessages = chatMessages.map((messageObject) => {
          let role = "";
          if (messageObject.sender === "ChatGPT") {
            role = "assistant";
          } else {
            role = "user";
          }
          return { role: role, content: messageObject.message}
        });
    
    
        // Get the request body set up with the model we plan to use
        // and the messages which we formatted above. We add a system message in the front to'
        // determine how we want chatGPT to act. 
        //const apiRequestBody = {
        //  "model": "gpt-3.5-turbo",
        //  "messages": [
        //    systemMessage,  // The system message DEFINES the logic of our chatGPT
        //    ...apiMessages // The messages from our chat with ChatGPT
        //  ]
        //}

        const apiRequestBody = {
          "query": chatMessages.slice(-1)[0].message
        }

        console.log(chatMessages.slice(-1)[0].message)

    
        await fetch("https://pandas-ai-agent-flask-6nt5mm2lpa-ue.a.run.app/chat", //"https://api.openai.com/v1/chat/completions", 
        {
          //method: "POST",
          //headers: {
          //  "Authorization": "Bearer " + API_KEY,
          //  "Content-Type": "application/json"
          //},
          //body: JSON.stringify(apiRequestBody)
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiRequestBody),
          mode: "cors"
        }).then((data) => {
          return data.json();
        }).then((data) => {
          console.log(data);
          //src={`data:image/jpeg;base64,${data}`}
          let type = ""
          let payload = ""
          if (data.data.type == "string") {
            type = "text"
            payload = data.data.value.toString();
          } else if (data.data.type == "plot") {
            type = "image"
            payload = {
              src: data.data.value,
              width: "100%",
              height: "100%"
            }
          } else if (data.data.type == "dataframe") {
            console.log("html");
            type = "html"
            payload = data.data.value
          }
          setMessages([...chatMessages, {
            //message: data.choices[0].message.content,
            message: data.data.value.toString(),
            sender: "ChatGPT",
            direction: "incoming",
            type: type,
            payload: payload
          }]);
          setIsTyping(false);
        });
      }

    if (!isOpen) return null;

    return (
        <div className={`chatbot ${isOpen ? 'open' : ''}`}>
            <MainContainer>
                <ChatContainer>
                    <MessageList
                        typingIndicator={typing ? <TypingIndicator content="Brawly is responding" /> : null}
                    >
                    {/*src, width, height, alt, className - image,  */}
                    {/*text, className, children - text */}
                        {messages.map((message, i) => {
                            return <Message key={i} model={message} />
                        })}
                    </MessageList>
                <MessageInput placeholder="Type message here" attachButton={false} onSend={handleSend}/>
                </ChatContainer>
            </MainContainer>
        </div>
    )
}

export default Chatbot;