import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import Dropdown, { DropdownTrigger, DropdownContent} from "react-simple-dropdown";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import RestaurantDataService from "./services/restaurant";
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css'; /* version 4 */
import './App.css';

import AddReview from "./components/add-review";
import Restaurant from "./components/restaurants";
import RestaurantsList from "./components/restaurants-list";
import Login from "./components/login";
import Chart from "./components/chart";
import Sidebar from "./components/sidebar";
import SlideShow from "./components/slideshow";
import TwitterContainer from "./components/twittercontainer";
import Posts from "./components/posts";
import SinglePost from "./components/singlepost";
import Chatbot from "./components/chatbot.js";

import Home from "./pages/home.js"

import logo from "./assets/brawliqlogo9.jpg";
import logo1 from "./assets/plainYellowBackground.jpg";
import logo2 from "./assets/fullName2.png";

import axios from "axios";
import PrivacyPolicy from "./components/privacy-policy.js";

function App() {
  const [user, setUser] = React.useState(null);
  const [fightsData, setFightsData] = useState({labels:[], datasets:[]})
  const [isChatbotOpen, setChatbotOpen] = useState(false);
  const [showFooter, setShowFooter] = useState(true);


  async function login(user = null) {
    setUser(user);
  }

  async function logout(user = null) {
    setUser(null)
  }

  const retrieveFights = () => {
    RestaurantDataService.getFights()
      .then(response => {
        console.log(response.data);
        setFightsData({
          labels: response.data.map(x => x.month),
          datasets: [
            {
              label: 'Rainfall',
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: 'rgba(0,0,0,1)',
              borderWidth: 2,
              data: response.data.map(x => x.temperature)
            }
          ]
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    retrieveFights();
    console.log(window.innerWidth);
    setShowFooter(!(isChatbotOpen && window.innerWidth < 480));
    sendWarmupRequest();
    if (getCookieConsentValue('mySiteCookieConsent') === 'true') {
      loadGTM();
    }
  }, [isChatbotOpen]);

  const loadGTM = () => {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-T2LFZH22');
  };

  async function sendWarmupRequest() {
    await fetch("https://pandas-ai-agent-flask-6nt5mm2lpa-ue.a.run.app/start", 
    {
          method: "POST"
        }).then((status) => {
          console.log(status);
          return status;
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    console.log(event.target.elements[0].value);
    const apiRequestBody = {
      "email": event.target.elements[0].value}
    // Handle form submission logic here
    // For example, you can access form data using event.target.elements
    // and send it to an API or perform other actions.
    await fetch("https://us-east1-elite-impact-411905.cloudfunctions.net/hubspot", 
    {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiRequestBody)
        }).then((data) => {
          return data.json();
        }).then((data) => {
          console.log(data);
          alert('subscribed!');
    });
    document.getElementById("subscribe-form").reset();
  };

  return (
    <body className={`app ${isChatbotOpen ? 'chatbot-open' : ''}`}>

      {/* <nav className="navbar navbar-expand navbar-dark bg-dark"> */}
      <header className="navbar navbar-dark bg-dark">
          {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button> */}
          {/* <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'}/> */}
          <div className="topLeft">
            <Sidebar />
          </div>
          <div className="topCenter">
            <a href="/">
              <img src={logo2} width="85" height="50" alt=""></img>
            </a>
          </div>
          {/* <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/restaurants"} className="nav-link">
                Restaurants
              </Link>
            </li>
            <li className="nav_item">
              { user ? (
                <a onClick={logout} className="nav-link" style={{cursor:'pointer'}}>
                  Logout {user.name}
                </a>
              ) : (
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
              )}
            </li>
            <li className="nav-item">
              <Link to={"/chart"} className="nav-link">
                Chart
              </Link>
            </li>
          </div> */}
          <div className="topRight">
            {/* <ul className="sub-nav">
              <li>
                <Link to={"/chart"}>
                  Chart
                </Link>
              </li>
              <li>
                { user ? (
                  <a onClick={logout}>
                    Logout {user.name}
                  </a>
                ) : (
                <Link to={"/login"}>
                  Login
                </Link>
                )}
              </li>
            </ul> */}
            <a role="button" onClick={() => isChatbotOpen ? setChatbotOpen(false) : setChatbotOpen(true)} title=""><i class="fa fa-crosshairs"></i></a>

            {/*<Dropdown className="account-dropdown">
              <DropdownTrigger>
                <i class="fa fa-user-circle" tabindex="0"></i>{/*<span className="account-dropdown__name">My Account</span> 
              </DropdownTrigger>
              <DropdownContent>
                <div className="account-dropdown__identity account-dropdown__segment">
                  Signed in as <strong>User</strong>
                </div>
                <ul className="account-dropdown__quick-links account-dropdown__segment">
                  <li className="account-dropdown__link">
                    <a className="account-dropdown__link__anchor" href="#">
                      Your profile
                    </a>
                  </li>
                  <li className="account-dropdown__link">
                    <a className="account-dropdown__link__anchor" href="#">
                      Your stars
                    </a>
                  </li>
                  <li className="account-dropdown__link">
                    <a className="account-dropdown__link__anchor" href="#">
                      Explore
                    </a>
                  </li>
                  <li className="account-dropdown__link">
                    <a className="account-dropdown__link__anchor" href="#">
                      Help
                    </a>
                  </li>
                </ul>
                <ul className="account-dropdown__management-links account-dropdown__segment">
                  <li className="account-dropdown__link">
                    <a className="account-dropdown__link__anchor" href="#">
                      Settings
                    </a>
                  </li>
                  <li className="account-dropdown__link">
                    <a className="account-dropdown__link__anchor" href="#">
                      Sign out
                    </a>
                  </li>
                </ul>
              </DropdownContent>
            </Dropdown> */}
          </div>
      </header>

      {/*
        <Switch>
          <Route exact path={["/", "/restaurants"]} component={Home} />
          <Route 
            path="/restaurants/:id/review"
            render={(props) => (
              <AddReview {...props} user={user} />
            )}
          />
          <Route 
            path="/restaurants/:id"
             render={(props) => (
              <Restaurant {...props} user={user} />
            )}
          />
          <Route 
            path="/login"
             render={(props) => (
               <Login {...props} login={login} />
            )}
          />
          <Route
            path="/chart"
            render={() => 
              <Chart chartData={fightsData} />
              }
          />
          <Route path="/post/abc">
            <SinglePost />
          </Route>
        </Switch>*/}
        <main>
          <BrowserRouter>
            <Switch>
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/chatbot" component={Chatbot} />
              <Route
                path="/"
                render={() => 
                  <Chart chartData={fightsData} />
                  }
              />
            </Switch>
          </BrowserRouter>
        </main>


      {/* <div className="content">
        <div style={{position: 'relative', height: '400px', width: '100%', border: '4px solid #b78c33'}}>
          <a href="#">Learn More</a>
        </div>
        <div style={{position: 'relative', width: '100%', marginTop: '1%', border: '4px solid #b78c33'}}><SlideShow /></div>
        <div className='grid' style={{position: 'relative', width: '100%', marginTop: '1%'}}>
          <div class="blog" style={{border: '4px solid #b78c33'}}>
            Recent Posts
            <Posts />
          </div>
          <div class="tweets" style={{border: '4px solid #b78c33'}}>
            <TwitterContainer /></div>
        </div>
      </div> */}
      <Chatbot isOpen={isChatbotOpen} />
      {showFooter && (
        <footer className='bg-dark'>
          <div className='subscribe'>
            <div className='subscribe-text'>
              <h3>Get The Insights</h3>
              <p>Be the first to know about new features and exclusive insights</p> 
            </div>     
            <form id="subscribe-form" onSubmit={handleSubmit}>
              <input type="text" name="email" placeholder="Enter your email" />
              <button type="submit"><b>Subscribe</b></button>
            </form>
          </div>
          <div className="social-media">
            <ul>
              <li><a href="#" title=""><i className="fa fa-facebook"></i></a></li>
              <li><a href="https://twitter.com/brawl_iq" title=""><i className="fa fa-twitter"></i></a></li>
              <li><a href="https://www.instagram.com/brawliq_official/" title=""><i className="fa fa-instagram"></i></a></li>
              <li><a href="#" title=""><i className="fa fa-linkedin"></i></a></li>
            </ul>
          </div>
          <p className='copyright'>Copyright © 2024 Brawl IQ Incorporated. All rights reserved.</p>
        </footer>
      )}
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="mySiteCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#FFF", backgroundColor: "#b78c33", fontSize: "13px" }}
        className="cookie-consent-transition"
        expires={150}
        onAccept={() => {
          loadGTM();
        }}
        overlay
      >
        This website uses cookies to enhance the user experience.{" "}
        {/*<Link to="/privacy-policy" style={{ color: "#b78c33" }}>Learn more</Link>*/}
        <a href='/privacy-policy' style={{ color: "#b78c33" }}>Learn more</a>
      </CookieConsent>
    </body>
  );
}

export default App;
