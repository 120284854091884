import http from "../http-common"

class RestaurantDataService {
    /* getAll(page = 0) {
      return http.get(`restaurants?page=${page}`);
      //return http.get(`?page=${page}`);
    }
  
    get(id) {
      return http.get(`/restaurant?id=${id}`);
      //return http.get(`/id/${id}`);
    }
  
    find(query, by = "name", page = 0) {
      return http.get(`restaurants?${by}=${query}&page=${page}`);
      //return http.get(`?${by}=${query}&page=${page}`);
    } 
  
    createReview(data) {
      return http.post("/review-new", data);
      //return http.post("/review", data);
    }
  
    updateReview(data) {
      return http.put("/review-edit", data);
      //return http.put("/review", data);
    }
  
    deleteReview(id, userId) {
      return http.delete(`/review-delete?id=${id}`, {data:{user_id: userId}});
      //return http.delete(`/review?id=${id}`, {data:{user_id: userId}});
    }
  
    getCuisines(id) {
      return http.get(`/cuisines`);
    } */

    getFights(id) {
      return http.get(`/test`);
    }

    getMonths(id) {
      return http.get(`/months`);
    }

    getFighters(id) {
      return http.get(`/fighters`);
    }

    find(query, by = "month", page = 0) {
      return http.get(`tests?${by}=${query}&page=${page}`);
      //return http.get(`?${by}=${query}&page=${page}`);
    }

    findFighterDetails(query, by = "fighter_name", page = 0) {
      return http.get(`fighter_details?${by}=${query}&page=${page}`);
      //return http.get(`?${by}=${query}&page=${page}`);
    }

    getPosts(id) {
      return http.get(`/posts`);
    }
  
  }
  
  export default new RestaurantDataService();